import { Link, navigate } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/Layout";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import PageSection from "../../components/PageSection";
import TechBanner from "../../components/TechBanner";
import OldServerImage from "../../components/images/OldServer";
import { SectionHeader, HelpItem } from "../../components/Services";

const LegacyReengineering = () => {
    return (
        <Layout>
            <Helmet>
                <title>Legacy System Re-engineering</title>
                <meta name="description" content="Legacy system redesign and re-engineering."></meta>
            </Helmet>
            <PageHeader title="Legacy System Re-engineering">
                Have an old internal system which is holding you back? Eborsoft can help.
            </PageHeader>
            <div className="container mx-auto px-8 mt-5">
                <section>
                    <h2 className="text-xl text-primary-default">What is a "legacy system or application"?</h2>
                    <p>
                        There is no clear-cut definition but in general refers to a system which for a number of reasons is considered to be in need of replacement. Such a system
                        may be operating as it was intended and that can make defining or obtaining a business case for replacement difficult.
                    </p>
                </section>

                <PageSection shadow header="Why consider replacing a legacy system?" image={
                    <div className="flex justify-center">
                        <div className="w-1/2 mb-3 md:m-6">
                            <OldServerImage />
                        </div>
                    </div>}>
                    
                    <SectionHeader>It may be expensive to maintain.</SectionHeader>
                    <p>
                        Legacy systems or applications are generally expensive to maintain. This can occur for a number of reasons e.g.organic growth over a long period of time or with multiple developers or development teams adding features ad-hoc
                        to fit needs without a greater sense of the overall picture. It may run on old hardware or an older operating system
                        which requires extra care to maintain. 
                    </p>
                    <p className="mt-2">
                        Often a lack of documentation (or even source code!) can result in
                        no-one having a clear picture of how it works.
                    </p>
                    
                    <SectionHeader>It may be fragile.</SectionHeader>
                    <p>
                        A fragile system is one which is easily broken either during maintenance (such as a change inadvertently breaking something) or even by end users (such as when using the application or system in a certain way).
                    </p>

                    <SectionHeader>It may be unsecure.</SectionHeader>
                    <p>
                        Legacy systems are generally less secure than their modern counterparts - this can be for a number of reasons:
                    </p>
                    <ul className="list-disc list-outside ml-10 mt-2">
                        <li>Older hardware or operating systems are required to use the system.</li>
                        <li>Internal development teams do not have a complete understanding of the system, or are not as engaged with it.</li>
                        <li>The architecture of the system may not have been designed for avoidance of modern threats.</li>
                        <li>It may require older, unsupported development tools.</li>
                    </ul>

                    <SectionHeader>It may be unable to integrate with other systems.</SectionHeader>
                    <p>
                        Businesses and their customers are becoming increasingly connected and older systems are often not easily
                        changed to embrace this. Integrations can range from exchanging data with other business systems, to front-facing
                        features which are requested by your customers e.g. ensuring that a customer is kept up-to-date with
                        their order or bill via a push notification, or a secure web portal where a customer may be able to view some
                        of their data which is locked up in an old backend server.
                    </p>
                    <p className="mt-2">
                        This can also affect employee productivity if staff have to jump between myriad of old, incompatible systems
                        to perform day-to-day tasks.
                    </p>
                </PageSection>

                <PageSection shadow header="How can Eborsoft help?">
                    <TechBanner />
                    <div className="mt-4">
                        We use the latest processes and technologies to ensure that you get the best solution possible.

                        <div>
                            <HelpItem title="Free initial consultation">
                                Thinking of replacing a legacy application or system? Why not <Link to="/contact">reach out</Link> for an initial consultation to
                                discuss your requirements free of charge. This can be as formal or informal as is required. We love talking
                                tech and would be excited to discuss your potential requirements!
                            </HelpItem>

                            <HelpItem title="System scoping/reverse engineering">
                                We will work with your business and even existing in-house developers to agree the design of any replacement system.
                                Have the developers of an old system moved on, or has information has been lost? Eborsoft is able to help you define
                                how your old system works and help you plan a migration strategy.
                            </HelpItem>

                            <HelpItem title="Best in class technology choice">
                                Eborsoft specialises in Microsoft based technologies as well as the latest web technologies such as ReactJS.
                                When implementing a solution we ensure that the technologies chosen are modern, standard and long-lasting. We can provide
                                cloud migration and management services if this is required.
                            </HelpItem>

                            <HelpItem title="Clear, upfront process">
                                We constantly work to our core values of <strong>communication</strong> and <strong>quality</strong>. As a customer and
                                technology partner we include you at every stage of the process. At the end of the project we can hand off to
                                your development team.
                            </HelpItem>

                            <HelpItem title="Secure source control">
                                Do you use an existing source control solution? Eborsoft can work with you to directly integrate and work with it.
                                If not, Eborsoft can manage your source control solution for you using either GitHub or Azure DevOps for a low
                                management charge.
                            </HelpItem>
                        </div>
                    </div>
                </PageSection>
                <div className="flex item-center">
                    <Button className="mx-auto mt-8" onClick={() => navigate("/contact")}>Interested? Contact Us</Button>
                </div>
            </div>
        </Layout>
    );
};

export default LegacyReengineering;
